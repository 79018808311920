export class TimeEntries {
  static setup() {
    $('#time_entry_shift_date').change(function() {
      TimeEntries.defaultDateField(this, $('#time_entry_start_time'));
    });
  }

  static defaultDateField(sourceInput, inputToDefault) {
    if (!inputToDefault.length) {
      return;
    }
    let sourceDate = $(sourceInput).val().split('T')[0];
    if (sourceDate) {
      let currentTime = inputToDefault.val().split('T')[1];
      currentTime = currentTime || this.currentTime();
      let newDateTime = `${sourceDate}T${currentTime}`;
      inputToDefault.val(newDateTime);
    }
  }
  
  static currentTime() {
    return new Date().toLocaleTimeString('en-US', { hour12: false,
      hour: 'numeric',
      minute: 'numeric' });
  }
}
