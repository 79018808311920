import moment from 'moment';
import { TimeEntries } from './time_entries';

// TODO: Remove this and replace with something rad_common_js this a temporary override
export class DateSetup {
  static setup() {
    $('input[type=date]').focus(function(){
      if($(this).val() === '') {
        $(this).val(moment().format('YYYY-MM-DD'));
        $(this).trigger('change');
      }
    });

    $('input[type=datetime-local]').focus(function(){
      if($(this).val() === '') {
        if(this.id === 'time_entry_end_time') {
          TimeEntries.defaultDateField($('#time_entry_start_time'), $('#time_entry_end_time'));
        } else {
          $(this).val(moment().format('YYYY-MM-DDTHH:mm'));
        }
        $(this).trigger('change');
      }
    });
  }
}
