export class SplitShifts {
  constructor() {
    this.primaryUserEndTime = document.getElementById('split_shift_primary_time_entry_end_time');
    this.splitUser1EndTime = document.getElementById('split_shift_split_user_1_end_time');
    this.splitUser2Id = document.getElementById('split_shift_split_user_2_id');
    this.splitUser2EndTime = document.getElementById('split_user_2_end_time');
  }

  setup() {
    if (!this.splitUser2Id) return;

    this.onSplitUser2IdChange();
    this.primaryUserEndTime.addEventListener('change', () => this.onSplitUser2IdChange());
    this.splitUser1EndTime.addEventListener('change', () => this.onSplitUser2IdChange());
    this.splitUser2Id.addEventListener('change', () => this.onSplitUser2IdChange());
  }

  onSplitUser2IdChange() {
    if (
      this.splitUser2Id.value &&
      this.splitUser2EndTime.value !== this.splitUser1EndTime.value &&
      this.splitUser2EndTime.value !== this.primaryUserEndTime.value
    ) {
      this.splitUser2EndTime.parentElement.classList.remove('d-none');
    } else {
      this.splitUser2EndTime.parentElement.classList.add('d-none');
    }
  }
}
